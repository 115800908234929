import { Box, VStack, Image, Grid, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import TempProfilePicture from "../../images/temp_profile_picture.svg";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
} from "../shared/opalite_text";
import InstagramIcon from "../../images/verify_socials_icons/verify_socials_icon_instagram_verified.svg";
import TikTokIcon from "../../images/verify_socials_icons/verify_socials_icon_tik_tok_verified.svg";
import CollectionDisplayBox from "./collection_display_box_(profile)";
import tempCollectionPicture from "../../images/temp_nft_picture.svg";

interface InfluencerProfileProps {}

const InfluencerProfile: React.FC<InfluencerProfileProps> = (props) => {
  const [content, setContent] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const openDrawer = () => {
    console.log(isOpen);
    onOpen();
  };
  const [influencerName, setInfluencerName] = useState("[Influencer Name]");
  const [influencerAbout, setInfluencerAbout] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut nulla vitae mauris auctor hendrerit."
  );
  const [influencerWebsite, setInfluencerWebsite] =
    useState("www.influencer.com");
  const [socials, setSocials] = useState([
    { socialType: "instagram", socialHandle: "@influencer" },
    { socialType: "tik_tok", socialHandle: "@influencer" },
  ]);

  useEffect(() => {
    setContent([
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
      {
        image: tempCollectionPicture,
        CollectionTitle: "Coffee Cup",
        priceSolana: 125,
        priceUSDollar: 39.99,
        influencer: "@Cup_O'Joe",
        CollectionNumber: "#8647",
        contentCount: "100",
      },
    ]);
  });

  const renderSocials = (socials) => {
    console.log(socials);
    const determineIcon = (iconType) => {
      switch (iconType) {
        case "instagram":
          return InstagramIcon;
        case "tik_tok":
          return TikTokIcon;
      }
    };
    return socials.map((social) => {
      return (
        <VStack
          marginBottom="1rem"
          display="flex"
          justifyContent="center"
          justifyItems="center"
          flexDirection="column"
        >
          <Image width="2rem" src={determineIcon(social.socialType)} />
          <OpaliteTextStandard fontSize=".75rem">
            {social.socialHandle}
          </OpaliteTextStandard>
        </VStack>
      );
    });
  };

  const renderContent = () => {
    const contentList = content.map((collection) => {
      return (
        <CollectionDisplayBox
          onClick={onOpen}
          isOpen={isOpen}
          onOpen={onOpen}
          image={collection.image}
          collectionTitle={collection.collectionTitle}
          priceSolana={collection.priceSolana}
          priceUSDollar={collection.priceUSDollar}
          influencer={collection.influencer}
          collectionNumber={collection.collectionNumber}
          contentCount={collection.contentCount}
        />
      );
    });
    return contentList;
  };

  return (
    <VStack
      height="100vh"
      overflow="scroll"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
    >
      <Box height="100%" padding="2rem">
        <VStack paddingTop="4rem">
          <Image width="6rem" src={TempProfilePicture} />
          <OpaliteTextSubTitle>{influencerName}</OpaliteTextSubTitle>
          <OpaliteTextStandard textAlign="center">
            {influencerAbout}
          </OpaliteTextStandard>
          <OpaliteTextStandard paddingTop="2rem" textAlign="center">
            {influencerWebsite}
          </OpaliteTextStandard>
        </VStack>
        <Box
          paddingInline="2rem"
          marginTop="2rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
        >
          {renderSocials(socials)}
        </Box>
        <Grid
          justifyItems="center"
          paddingTop="2rem"
          gap={4}
          templateColumns={{
            base: "repeat(2, 4fr)",
            sm: "repeat(3, 4fr)",
            lg: "repeat(4, 4fr)",
          }}
        >
          {renderContent()}
        </Grid>
      </Box>
    </VStack>
  );
};

export default InfluencerProfile;
