import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import InfluencerProfile from "../components/influencer_profile/influencer_profile";

function InfluencerProfilePage() {
  return (
    <ChakraProvider>
      <Header />
      <InfluencerProfile />
    </ChakraProvider>
  );
}

export default InfluencerProfilePage;
